import { useLocation, useParams } from 'react-router-dom';
import Heading from './components/Heading';
import Sidebar from './components/Sidebar';
import { usePlans } from './context/PlansContext';
import { Outlet } from 'react-router-dom';
import NotFound from './pages/notFound/NotFound';
import { useEffect, useState } from 'react';
import LoadingComponent from '@/components/LoadingComponent';
import LogoutModal from './components/modals/LogoutModal';
import KycForm from './components/modals/kycForm';
import PlanExpiry from './components/modals/PlanExpiry';
import GetOnboard from './components/modals/GetOnboard';
import useAuthStore from './store/Auth';

function Layout({
  children,
  createTicketsResponse,
  sentProjects,
  setSentProjects,
  isChecking,
  setIsChecking,
  openPendingProjects,
  setOpenPendingProjects,
  collapsed,
  setCollapsed,
}) {
  const location = useLocation();
  const params = useParams();
  const [isExistingRoute, setIsExistingRoute] = useState(false);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [showKYCForm, setShowKYCForm] = useState(false);
  const [isKYCCompleted, setIsKYCCompleted] = useState(false);
  const [showPlanExpiry, setShowPlanExpiry] = useState(false);
  const [showGetOnboard, setShowGetOnboard] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const { isSubscribed, subscription, hasSubscriptionEnded } = usePlans();

  // const isSubscribed = false;
  // const subscription = null;
  // const hasSubscriptionEnded = true;

  const authStore = useAuthStore();

  const isNew = authStore?.user?.isNew;
  // const isNew = false;
  const isAuthenticated = authStore?.isAuthenticated;

  const isAuthPage =
    location.pathname === '/login' || location.pathname === '/signup';

  //if you create a new route, you should add it to the "routes" array below
  const routes = [
    {
      name: 'login',
      link: '/login',
    },
    {
      name: 'signup',
      link: '/signup',
    },
    {
      name: 'create-tickets',
      link: '/create',
    },
    {
      name: 'projects',
      link: '/projects',
    },
    {
      name: 'projects',
      link: '/projects/:projectId',
    },
    {
      name: 'integrations',
      link: '/integrations',
    },
    {
      name: 'integrations',
      link: '/integrations/Jira',
    },
    {
      name: 'integrations',
      link: '/integrations/OpenAI',
    },
    {
      name: 'plans',
      link: '/plans',
    },
    {
      name: 'insights',
      link: '/',
    },
    {
      name: 'epic-insights',
      link: '/insights/:epicId',
    },
  ];

  useEffect(() => {
    const checkExistingRoute = () => {
      // Check if the current path is in the predefined routes
      const existingRoute = routes.some(
        (route) => location.pathname === route.link
      );

      // Check dynamic routes using params
      const dynamicRoute = routes.some((route) => {
        const regex = new RegExp(`^${route.link.replace(/:\w+/g, '[^/]+')}$`);
        return regex.test(location.pathname);
      });

      setIsExistingRoute(existingRoute || dynamicRoute);
      setLoading(false); // Set loading to false after the route check is complete
    };

    checkExistingRoute();
  }, [location, params]);

  // useEffect(() => {
  //   // const isSubscribed = false;
  //   if (isAuthenticated) {
  //     if (isNew) {
  //       setShowKYCForm(true);
  //     } else {
  //       if (!isSubscribed) {
  //         setShowGetOnboard(true);
  //       } else if (hasSubscriptionEnded) {
  //         setShowPlanExpiry(true);
  //       } else {
  //         setShowGetOnboard(false);
  //       }
  //     }
  //   }
  //   setLoading(false);
  // }, [isAuthenticated, isNew, isSubscribed, hasSubscriptionEnded]);

  useEffect(() => {
    if (isAuthenticated) {
      if (isNew) {
        setShowKYCForm(true);
      } else {
        const allowedPagesForGetOnboard = ['/create', '/projects'];
        const isOnAllowedPage = allowedPagesForGetOnboard.includes(
          location.pathname
        );

        if (!isSubscribed && isOnAllowedPage) {
          setShowGetOnboard(true);
        } else if (hasSubscriptionEnded && isOnAllowedPage) {
          setShowPlanExpiry(true);
        } else {
          setShowGetOnboard(false);
        }
      }
    }
    setLoading(false);
  }, [
    isAuthenticated,
    isNew,
    isSubscribed,
    hasSubscriptionEnded,
    location.pathname,
  ]);

  if (isAuthPage) {
    return <>{children}</>;
  }

  if (loading) {
    return (
      <div className="h-screen bg-black-6">
        <LoadingComponent />
      </div>
    );
  }
  const closeKYCForm = () => {
    setShowKYCForm(false);
    setIsKYCCompleted(true);
    if (!isSubscribed) {
      setShowGetOnboard(true);
    }
  };

  const closePlanExpiry = () => {
    setShowPlanExpiry(false);
  };

  const closeGetOnboard = () => {
    setShowGetOnboard(false);
  };

  const handleCloseLogoutModal = () => {
    setConfirmLogout(false);
  };

  return (
    <>
      {isExistingRoute ? (
        <>
          <div className="relative bg-black-7 text-gray-4 h-screen w-full flex flex-col">
            {/* Header */}
            <div className="bg-black-7 z-20 absolute top-0 w-full flex items-center justify-between py-4 h-[80px] px-[20px] lg:px-[20px] md:px-[20px] border-b-0.5 border-gray-2">
              <Heading
                createTicketsResponse={createTicketsResponse}
                sentProjects={sentProjects}
                setSentProjects={setSentProjects}
                isChecking={isChecking}
                setIsChecking={setIsChecking}
                openPendingProjects={openPendingProjects}
                setOpenPendingProjects={setOpenPendingProjects}
                isSubscribed={isSubscribed}
                subscription={subscription}
                hasSubscriptionEnded={hasSubscriptionEnded}
              />
            </div>

            <div className="flex flex-1 overflow-hidden">
              {/* Sidebar */}
              <div
                className={`${
                  collapsed ? 'sidebarWidth1 w-[5%]' : 'sidebarWidth2 w-[17%]'
                } transition-width duration-500 h-full`}
              >
                <Sidebar
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  setConfirmLogout={setConfirmLogout}
                />
              </div>

              {/* Main Content */}
              <div
                className={`${
                  collapsed ? 'w-[95%]' : 'w-[83%]'
                } overflow-hidden h-full`}
              >
                {children}
              </div>
            </div>
          </div>
          <Outlet />
        </>
      ) : (
        <NotFound />
      )}
      {showKYCForm && <KycForm onClose={closeKYCForm} />}
      {showPlanExpiry && <PlanExpiry onClose={closePlanExpiry} />}
      {showGetOnboard && <GetOnboard onClose={closeGetOnboard} />}
      {confirmLogout && <LogoutModal onClose={handleCloseLogoutModal} />}
    </>
  );
}

export default Layout;
