import CollapseIcon from '@/icons/CollapseIcon';
import React, { useState, useEffect } from 'react';
import MenuItem from './MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import Integrations from '@/icons/Integrations';
import LogoutIcon from '@/icons/LogoutIcon';
import GetHelpIcon from '@/icons/GetHelpIcon';
import HomeIcon from '@/icons/HomeIcon';
import ProjectIcon from '@/icons/ProjectIcon';
import MoneyIcon from '@/icons/MoneyIcon';
import Insights from '../icons/files/Insights';

export default function Sidebar({ collapsed, setCollapsed, setConfirmLogout }) {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      title: 'Insights',
      icon: Insights,
      link: '/',
      isNew: true,
    },
    {
      title: 'Create tickets',
      icon: HomeIcon,
      link: '/create',
    },
    {
      title: 'Projects',
      icon: ProjectIcon,
      link: '/projects',
    },
    {
      title: 'Integrations',
      icon: Integrations,
      link: '/integrations',
    },
    {
      title: 'Subscriptions',
      icon: MoneyIcon,
      link: '/plans',
    },
  ];
  const utilityItems = [
    {
      title: 'Get Help',
      icon: GetHelpIcon,
      link: 'https://crafterai.gitbook.io/',
      target: '_blank',
    },
  ];

  const handleShowLogout = () => {
    setConfirmLogout(true);
  };

  return (
    <nav
      className={`h-full flex flex-col gap-5 pt-[100px] pb-[20px] px-1 sidebarBg transition-width duration-500`}
    >
      <div className={`flex self-end`}>
        <div className="w-[50px] py-2 flex items-center justify-center">
          <CollapseIcon collapsed={collapsed} setCollapsed={setCollapsed} />
        </div>
      </div>
      <div className="w-full flex flex-col justify-between h-full">
        <ul className="w-full flex flex-col gap-2">
          {menuItems.map((menuItem, index) => {
            const isActive =
              menuItem.link === '/'
                ? location.pathname === '/'
                : location.pathname.startsWith(menuItem.link);
            return (
              <li key={index}>
                <MenuItem
                  title={menuItem.title}
                  icon={menuItem.icon}
                  link={menuItem.link}
                  isNew={menuItem.isNew}
                  collapsed={collapsed}
                  active={isActive}
                  onClick={() => navigate(menuItem.link)}
                />
              </li>
            );
          })}
        </ul>

        <div className="flex flex-col gap-2">
          <ul className="w-full flex flex-col gap-2">
            {utilityItems.map((utilityItem, index) => {
              const isActive = location.pathname.startsWith(utilityItem.link);
              return (
                <li key={index}>
                  <MenuItem
                    title={utilityItem.title}
                    icon={utilityItem.icon}
                    link={utilityItem.link}
                    target={utilityItem.target}
                    collapsed={collapsed}
                    active={isActive}
                    onClick={() => navigate(utilityItem.link)}
                  />
                </li>
              );
            })}
          </ul>
          <div
            onClick={handleShowLogout}
            className="cursor-pointer px-3 py-2 rounded-sm flex items-center gap-2 hover:bg-black-1 text-error-1"
          >
            <div className="w-[50px] py-2 flex items-center justify-center">
              <LogoutIcon />
            </div>
            {collapsed ? '' : <p>Logout</p>}
          </div>
        </div>
      </div>
    </nav>
  );
}
