import React, { useEffect, useState } from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';

export default function MenuItem({
  icon: Icon,
  title,
  link,
  isNew,
  target,
  collapsed,
  active,
}) {
  const location = useLocation();
  const [showLabel, setShowLabel] = useState(true);

  const isActive =
    link === '/'
      ? location.pathname === '/'
      : location.pathname.startsWith(link);

  useEffect(() => {
    const hasSeenPage = localStorage.getItem('hasSeenPage');
    setShowLabel(!hasSeenPage);
  }, []);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        localStorage.setItem('hasSeenPage', 'true');
        setShowLabel(false);
      }, 7000);
    }
  }, [isActive]);
  return (
    <NavLink
      to={link}
      target={target}
      className={`px-3 py-2 flex items-center gap-2 rounded-sm relative hover:bg-gray-7 ${
        active ? 'bg-gray-7 font-semibold text-white-1' : ''
      }`}
    >
      <div className="w-[50px] py-2 flex items-center justify-center">
        <Icon active={active} />
      </div>
      {!collapsed && <p>{title}</p>}
      {isNew && showLabel && (
        <label
          className={`text-uppercase text-white-1 font-semibold p-[2px] bg-error-3 rounded-sm text-[6px] mb-2 ${collapsed ? 'absolute left-[50px] top-[10px]' : 'relative'}`}
        >
          NEW
        </label>
      )}
    </NavLink>
  );
}
