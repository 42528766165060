import React, { useState } from 'react';
import Caret from '../../icons/Caret';
import ProjectsIcon from '../../icons/ProjectsIcon';
import LoadingComponent from '../LoadingComponent';

export default function ProjectDropdown({
  id,
  name,
  disabled,
  label,
  isRequired,
  options,
  placeholder,
  isProject,
  onChange,
  value,
  fetchData,
  fetchJiraProjectEpics,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [loading, setLoading] = useState(false);

  const handleOpenDropdown = async (e) => {
    e.preventDefault();
    if (
      !isOpen &&
      fetchData &&
      typeof fetchData === 'function' &&
      selectedOption === ''
    ) {
      setIsOpen(true);
      setLoading(true);
      await fetchData();
      setLoading(false);
    }
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    if (onChange && typeof onChange === 'function') {
      onChange(option);
    }
    fetchJiraProjectEpics(option.key);
    setIsOpen(false);
  };

  return (
    <div className="w-full flex flex-col items-start gap-2 project-dropdown">
      <label className="text-sm text-gray-4 font-semibold">
        {label}
        {isRequired && <span className="text-sm text-error-1">*</span>}
      </label>
      <div className="w-full relative">
        <button
          // onBlur={setIsOpen(false)}
          disabled={disabled}
          id={id}
          name={name}
          onClick={handleOpenDropdown}
          className={`flex w-full h-[40px] items-center gap-2 justify-between ${
            selectedOption ? 'text-white-2' : 'text-gray-2'
          } ${disabled ? 'cursor-not-allowed' : ''} text-gray-2 text-white bg-transparent rounded-md py-[10px] px-[14px] border-1 border-gray-2 focus:outline-none`}
        >
          <span className="flex items-center gap-2 text-gray-1 ">
            {isProject && <ProjectsIcon2 />}
            {selectedOption ? (
              <p className="text-white-1 font-semibold capitalize">
                {selectedOption?.name}
              </p>
            ) : (
              <p className="text-gray-4 font-normal">{placeholder}</p>
            )}
          </span>
          <Caret />
        </button>

        {isOpen && (
          <div className="rounded-md absolute w-full mt-2 max-h-[150px] overflow-y-scroll flex flex-col items-start text-gray-4 modalBg">
            {loading ? (
              <LoadingComponent />
            ) : options.length > 0 ? (
              <ul className="w-full">
                {options.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectOption(option)}
                    className="w-full text-left py-3 px-4 border-b-0.5 border-black-1 text-gray-4 hover:text-white-2 cursor-pointer capitalize"
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="w-full text-center py-6 px-4 text-gray-4">
                No Jira Projects found
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export const ProjectsIcon2 = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M12.9165 11.4584H11.1082C9.62484 11.4584 8.4165 10.2584 8.4165 8.76671V6.95837C8.4165 6.61671 8.1415 6.33337 7.7915 6.33337H5.14984C3.22484 6.33337 1.6665 7.58337 1.6665 9.81671V15.35C1.6665 17.5834 3.22484 18.8334 5.14984 18.8334H10.0582C11.9832 18.8334 13.5415 17.5834 13.5415 15.35V12.0834C13.5415 11.7334 13.2582 11.4584 12.9165 11.4584Z"
        fill="#CCE6EE"
      />
      <path
        d="M14.8501 2.16663H13.2085H12.3001H9.9418C8.05846 2.16663 6.53346 3.36663 6.4668 5.50829C6.5168 5.50829 6.55846 5.49996 6.60846 5.49996H8.9668H9.87513H11.5168C13.4418 5.49996 15.0001 6.74996 15.0001 8.98329V10.625V12.8833V14.525C15.0001 14.575 14.9918 14.6166 14.9918 14.6583C16.8501 14.6 18.3335 13.3666 18.3335 11.1916V9.54996V7.29163V5.64996C18.3335 3.41663 16.7751 2.16663 14.8501 2.16663Z"
        fill="#0182AB"
      />
      <path
        d="M9.9832 6.45837C9.72487 6.20004 9.2832 6.37504 9.2832 6.73337V8.9167C9.2832 9.83337 10.0582 10.5834 11.0082 10.5834C11.5999 10.5917 12.4249 10.5917 13.1332 10.5917C13.4915 10.5917 13.6749 10.175 13.4249 9.92504C12.5165 9.0167 10.8999 7.3917 9.9832 6.45837Z"
        fill="#0182AB"
      />
    </svg>
  );
};
