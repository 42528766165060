import React, { useRef, useState } from 'react';
import styles from './PromptInput2.module.css';
import useInsightsStore from '../../../store/Insights';
import { fetchAIResponse } from '../../../store/Chats';

export default function PromptInput2({ onClick, scrollToBottom }) {
  const { selectedEpic, setInsightActiveComponent, selectedProject } =
    useInsightsStore();
  const textareaRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      setIsDisabled(textareaRef.current.value.trim() === '');
    }
  };

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    setInsightActiveComponent('chats');
    setInputValue('');
    scrollToBottom();
    try {
      await fetchAIResponse(
        inputValue,
        selectedProject.key,
        selectedEpic.key
      ).then(() => {
        scrollToBottom();
      });
    } catch (error) {
      scrollToBottom();
      console.error('Error sending message:', error);
    }
    scrollToBottom();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line
      if (!isDisabled) {
        handleSend();
      }
    }
  };

  return (
    <div
      className={`${styles.prompt_input_bg} relative pl-[30px] pr-[80px] w-full flex items-center gap-5 rounded-full  py-2 `}
    >
      <div
        className={`${isDisabled ? '' : 'overflow-auto'}  w-full h-[60px] py-5 ml-[20px]`}
      >
        <textarea
          ref={textareaRef}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="w-full overflow-hidden bg-transparent resize-none  focus:outline-none placeholder:text-gray-3 text-white-3 h-full"
          placeholder="Chat with Crafter"
          // rows={2}
        />
      </div>

      <div className="absolute right-5">
        <button
          disabled={isDisabled}
          onClick={handleSend}
          className={`${styles.prompt_button_1} w-fit p-[6px] h-fit rounded-full ${isDisabled ? 'bg-gray-2' : ''} `}
        >
          <div
            className={`-rotate-45 w-[30px] h-[30px] ${isDisabled ? 'text-gray-2' : ''} `}
          >
            <ButtonSvg />
          </div>
        </button>
      </div>
    </div>
  );
}

export const ButtonSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.082 9.92776L11.0953 4.93442C4.38697 1.57442 1.63364 4.32776 4.99364 11.0361L6.00864 13.0661C6.30031 13.6611 6.30031 14.3494 6.00864 14.9444L4.99364 16.9628C1.63364 23.6711 4.37531 26.4244 11.0953 23.0644L21.082 18.0711C25.562 15.8311 25.562 12.1678 21.082 9.92776ZM17.3136 14.8744H11.0136C10.5353 14.8744 10.1386 14.4778 10.1386 13.9994C10.1386 13.5211 10.5353 13.1244 11.0136 13.1244H17.3136C17.792 13.1244 18.1886 13.5211 18.1886 13.9994C18.1886 14.4778 17.792 14.8744 17.3136 14.8744Z"
        fill="currentColor"
      />
    </svg>
  );
};
